import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";
import Image1 from "../../images/as6.jpeg";
import Image2 from "../../images/as7.jpg";
import Image3 from "../../images/as5.jpeg";
import Image4 from "../../images/as4.jpg";
import Logo from "../../images/logo.svg";
import arrowright from "../../images/arrow.svg";
import "./home.css";
import {
  ABOUT,
  HERO_CONTENT,
  LOGO,
  NAVIGATION,
  NFT,
  ROAD_MAP,
} from "../../utils/globals";

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <header>
        <button className="navbar-toggler" type="button" id="toggleMenu">
          <span></span>
        </button>
        <nav className="navbar navbar-expand-lg">
          <a className="navbar-brand" href="/">
            <img src={LOGO} alt="Avashark" className="logo" />
          </a>

          <div className="menu-navbar" id="menu">
            <ul className="navbar-nav ml-auto custom-navbar-nav">
              {NAVIGATION.nav.map((navs, index) => (
                <li key={index} className={navs.className}>
                  <a className="nav-link" href={navs.path}>
                    {navs.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </header>
      <section className="home home-bg">
        <div className="container">
          <div className="row m-0">
            <div className="col-md-12">
              <h1>Bold as a shark</h1>
              <p className="content">{HERO_CONTENT.SUB}</p>
              <div className="w-100">
                <a
                  href="images/Avasharks-Whitepaper.pdf"
                  download
                  className="download-link"
                >
                  {HERO_CONTENT.BUTTON_NAME}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-bg d-block" id="AboutUs">
        <h1 className="title text-center mb-5">{ABOUT.TITLE}</h1>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="about-img-left">
                <img src={Logo} className="w-75" alt="AvaSharks" />
              </div>
            </div>
            <div className="col-md-6">
              <p className="content">{ABOUT.SUB1}</p>
              <p className="content">{ABOUT.SUB2}</p>
              <p className="content">{ABOUT.SUB3}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="prof-sec about-bg" id="NFT">
        <div className="circle">
          {NFT.IMAGES.map((nft, index) => (
            <div className="circle-img" key={index}>
              <img src={nft} alt="no image " />
            </div>
          ))}
          <div className="main-imp">
            <img src={LOGO} alt="no image " />
          </div>
        </div>
      </section>

      <section className="road-map-sec" id="roadMap">
        <h1 className="title text-center mb-5">{ROAD_MAP.TITLE}</h1>
        <div className="container">
          <div className="row m-0">
            <div className="col-md-12">
              <div className="rd-area">
                <div className="line"></div>
                <div className="d-flex justify-content-start rd-main-bx">
                  <div className="rd-bx">
                    <div className="rd-bx-badge">
                      <img src={LOGO} alt="badge" />
                    </div>
                    <div>
                      <h6 className="rd-bx-title">{ROAD_MAP.ROADS[0].Q}</h6>
                      <p className="rd-bx-txt mb-2">{ROAD_MAP.ROADS[0].sub}</p>
                      <p className="rd-bx-txt">{ROAD_MAP.ROADS[0].sub2}</p>
                    </div>
                  </div>
                  <svg height="200" width="230" className="svg-line">
                    <line
                      x1="20"
                      y1="50%"
                      x2="95%"
                      y2="50%"
                      className="svg-drawline"
                    />
                    <circle
                      cx="9%"
                      cy="50%"
                      r="35%"
                      className="cscg-line-dot cscg-line-dot1"
                    />
                    <circle
                      cx="96%"
                      cy="50%"
                      r="4.5%"
                      className="cscg-line-dot cscg-line-dot2"
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                <div className="d-flex justify-content-end rd-main-bx rd-main-bx2">
                  <div className="rd-bx rd-bx-rt">
                    <div className="rd-bx-badge">
                      <img src={Logo} alt="badge" />
                    </div>
                    <div>
                      <h6 className="rd-bx-title">{ROAD_MAP.ROADS[1].Q}</h6>
                      <p className="rd-bx-txt mb-2">{ROAD_MAP.ROADS[1].sub}</p>
                      <p className="rd-bx-txt">{ROAD_MAP.ROADS[1].sub2}</p>
                    </div>
                  </div>
                  <svg height="200" width="230" className="svg-line">
                    <line
                      x1="20"
                      y1="50%"
                      x2="95%"
                      y2="50%"
                      className="svg-drawline"
                    />
                    <circle
                      cx="9%"
                      cy="50%"
                      r="35%"
                      className="cscg-line-dot cscg-line-dot1"
                    />
                    <circle
                      cx="96%"
                      cy="50%"
                      r="10"
                      className="cscg-line-dot cscg-line-dot2"
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                <div className="d-flex justify-content-start rd-main-bx">
                  <div className="rd-bx">
                    <div className="rd-bx-badge">
                      <img src={Logo} alt="badge" />
                    </div>
                    <div>
                      <h6 className="rd-bx-title">{ROAD_MAP.ROADS[2].Q}</h6>
                      <p className="rd-bx-txt">{ROAD_MAP.ROADS[2].sub}</p>
                    </div>
                  </div>
                  <svg height="200" width="230" className="svg-line">
                    <line
                      x1="20"
                      y1="50%"
                      x2="95%"
                      y2="50%"
                      className="svg-drawline"
                    />
                    <circle
                      cx="9%"
                      cy="50%"
                      r="35%"
                      className="cscg-line-dot cscg-line-dot1"
                    />
                    <circle
                      cx="96%"
                      cy="50%"
                      r="10"
                      className="cscg-line-dot cscg-line-dot2"
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
                <div className="d-flex justify-content-end rd-main-bx rd-main-bx2">
                  <div className="rd-bx rd-bx-rt">
                    <div className="rd-bx-badge">
                      <img src={Logo} alt="badge" />
                    </div>
                    <div>
                      <h6 className="rd-bx-title">{ROAD_MAP.ROADS[3].Q}</h6>
                      <p className="rd-bx-txt">{ROAD_MAP.ROADS[3].sub}</p>
                    </div>
                  </div>
                  <svg height="200" width="230" className="svg-line">
                    <line
                      x1="20"
                      y1="50%"
                      x2="95%"
                      y2="50%"
                      className="svg-drawline"
                    />
                    <circle
                      cx="9%"
                      cy="50%"
                      r="35%"
                      className="cscg-line-dot cscg-line-dot1"
                    />
                    <circle
                      cx="96%"
                      cy="50%"
                      r="10"
                      className="cscg-line-dot cscg-line-dot2"
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq home-bg" id="faq">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="faq-body">
                <h1 className="title text-center mb-5">FAQ</h1>
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="hd1">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#faq1"
                          aria-expanded="true"
                          aria-controls="faq1"
                        >
                          What is an NFT ?
                          <img src={arrowright} alt="row" className="arrow" />
                        </button>
                      </h5>
                    </div>

                    <div
                      id="faq1"
                      className="collapse show"
                      aria-labelledby="hd1"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        An NFT, which stands for non-fungible token, is a unique
                        unit of data employing technology that allows digital
                        content from videos to songs to images to become logged
                        and authenticated on cryptocurrency blockchains.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="hd2">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq2"
                          aria-expanded="false"
                          aria-controls="faq2"
                        >
                          What is Avalanche ?
                          <img src={arrowright} alt="row" className="arrow" />
                        </button>
                      </h5>
                    </div>
                    <div
                      id="faq2"
                      className="collapse"
                      aria-labelledby="hd2"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        Avalanche is a decentralized, open-source blockchain
                        with smart contract functionality. AVAX is the native
                        cryptocurrency of the platform.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="hd3">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq3"
                          aria-expanded="false"
                          aria-controls="faq3"
                        >
                          What are AvaShark NFTs ?
                          <img src={arrowright} alt="row" className="arrow" />
                        </button>
                      </h5>
                    </div>
                    <div
                      id="faq3"
                      className="collapse"
                      aria-labelledby="hd3"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        AvaSharks are a collection of 10,000 3D fully rigged
                        NFTs that offer holders royalty sharing rights to a next
                        generation wagering platform, and an oppurtunity for
                        holders to make passive income
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="hd4">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq4"
                          aria-expanded="false"
                          aria-controls="faq4"
                        >
                          What is the mint price ?
                          <img src={arrowright} alt="row" className="arrow" />
                        </button>
                      </h5>
                    </div>
                    <div
                      id="faq4"
                      className="collapse"
                      aria-labelledby="hd4"
                      data-parent="#accordion"
                    >
                      <div className="card-body">1 AVAX</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="hd5">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq5"
                          aria-expanded="false"
                          aria-controls="faq5"
                        >
                          When can I mint?
                          <img src={arrowright} alt="row" className="arrow" />
                        </button>
                      </h5>
                    </div>
                    <div
                      id="faq5"
                      className="collapse"
                      aria-labelledby="hd5"
                      data-parent="#accordion"
                    >
                      <div className="card-body">February 20th 9pm UTC</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="hd6">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq6"
                          aria-expanded="false"
                          aria-controls="faq6"
                        >
                          Is there a whitelist?
                          <img src={arrowright} alt="row" className="arrow" />
                        </button>
                      </h5>
                    </div>
                    <div
                      id="faq6"
                      className="collapse"
                      aria-labelledby="hd6"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        No, we will be having a fair mint. First come, first
                        serve basis.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="hd7">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq7"
                          aria-expanded="false"
                          aria-controls="faq7"
                        >
                          How do I setup Metamask on Avalanche C-chain ?
                          <img src={arrowright} alt="row" className="arrow" />
                        </button>
                      </h5>
                    </div>
                    <div
                      id="faq7"
                      className="collapse"
                      aria-labelledby="hd7"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <a
                          href="https://support.avax.network/en/articles/4626956-how-do-i-set-up-metamask-on-avalanche"
                          className="text-warning"
                          target="_blank"
                        >
                          https://support.avax.network/en/articles/4626956-how-do-i-set-up-metamask-on-avalanche
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
