import Home from "./components/Home/home";
function App() {
  return (
    <>
      <Home></Home>
    </>
  );
}

export default App;
