import LOGOIMG from "../images/logo.svg";
import Image1 from "../images/as6.jpeg";
import Image2 from "../images/as7.jpg";
import Image3 from "../images/as5.jpeg";
import Image4 from "../images/as4.jpg";
export const NAVIGATION = {
  nav: [
    { name: "Home", path: "/", className: "nav-item active" },
    { name: "About Us", path: "#AboutUs", className: "nav-item" },
    { name: "Rewards", path: "/rewards", className: "nav-item" },
    { name: "Roadmap", path: "#roadMap", className: "nav-item" },
    { name: "FAQ", path: "#faq", className: "nav-item" },
  ],
};

export const HERO_CONTENT = {
  BUTTON_NAME: "White Paper",
  SUB: "The Avasharks are 10,000 collection of sharks that have been protecting the high seas for the past 400 Million years",
};

export const ABOUT = {
  TITLE: "ABOUT US",
  SUB1: "  AvaSharks are a collection of 10,000 NFT's, born and bred on the Avalanche Blockchain.",
  SUB2: "AvaSharks first surfaced on December 3, 2021. Since then, we have been busy building a grass roots community and growing our network within the Avalanche space.",
  SUB3: "As we are the first wagering platform to enter this space, our aim is to grow into a major player within the blockchain betting industry and to transform into a high end fully operational gaming platform",
};

export const NFT = {
  IMAGES: [Image1, Image2, Image3, Image4],
};

export const ROAD_MAP = {
  TITLE: "Road Map",
  ROADS: [
    {
      Q: "Q1 2022",
      sub: "Fully Rigged 3D artwork on AVAX ready to be deployed in future Metaverse games.",
      sub2: "Atlantis Gate - Coin Flip, PvP betting platform.",
    },
    {
      Q: "Q2 2022",
      sub: "Shark Gen 2 Release - All future new generation drops will be free exclusively for Shark Holders.",
      sub2: "Lottery - injected with % of proceeds from Shark Vault. Lottery will be organized every quarter and will be accessible to all Shark holders and ticket holders. Ticket holders will be bringing in additional capital to the lottery prizes, therefore will represent an additional stream of revenue.",
    },
    {
      Q: "Q3 2022",
      sub: "Shark Race - An underwater P2E racing game with special breeding abilities to upgrade your shark's skill level.",
      sub2: "Atlantis Gate - Coin Flip, PvP betting platform.",
    },
    {
      Q: "Q4 and onwards",
      sub: "An iterative approach will be developed and added onto the road map, as we plan to grow the Atlantis Gate to eventually operate as fully operational online gaming platform.",
      sub2: "Atlantis Gate - Coin Flip, PvP betting platform.",
    },
  ],
};

export const NFTS = {
  TITLE: "What is an NFT ?",
};

export const LOGO = LOGOIMG;
