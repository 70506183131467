import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import axios from "axios";
import "./rewards.css";
import { concat } from "ethers/lib/utils";
import error_loading from "../../images/error_loading.png";

function Rewards() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingRewards, setClaimRewards] = useState(false);
  const [sendingNfts, setSendingNfts] = useState(false);
  const [bulkSend, setBulkSend] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [totalSharks, setTotalSharks] = useState(1);
  const [receiver, setReceiver] = useState("");
  const [allNftImages, setAllNftImages] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [feedback, setFeedback] = useState(`Click buy to claim your reward.`);
  const [CONFIG, SET_CONFIG] = useState({
    AVASHARKSVAULTCONTRACT: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    WEI_COST: 0,
    GAS_LIMIT: 0,
    BASE_FEE: 0,
    SHOW_BACKGROUND: true,
  });

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.id]: event.target.checked,
    });
  };

  const claimRewards = () => {
    let gasLimit = CONFIG.GAS_LIMIT + 15000 * totalSharks;
    let baseFee = CONFIG.BASE_FEE;
    console.log("Gas limit: ", gasLimit);
    setFeedback(`Claiming your Rewards}...`);
    setClaimRewards(true);
    blockchain.avaSharksVault.methods
      .claimRewardsForAddress()
      .send({
        gasLimit: String(gasLimit),
        //maxFeePerGas: String(baseFee * 40),
        //maxPriorityFeePerGas: String(baseFee),
        to: CONFIG.AVASHARKSVAULTCONTRACT,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again.");
        setClaimRewards(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`Claimed`);
        setClaimRewards(false);
        dispatch(fetchData(blockchain.account, CONFIG.BULKSENDERCONTRACT));
      });
  };

  const bulkSender = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let baseFee = CONFIG.BASE_FEE;
    console.log("Gas limit: ", gasLimit);
    setFeedback(`Sending your NFTs...`);
    setSendingNfts(true);
    console.log("checkedItems: ", checkedItems);
    let nfts = [];
    for (const [key, value] of Object.entries(checkedItems)) {
      console.log(key, value);
      if (value) {
        nfts.push(key.split("#")[1]);
      }
    }
    console.log("nfts", nfts);
    console.log("receiver: ", receiver);
    if (nfts.length > 0 && receiver != "") {
      blockchain.bulkSender.methods
        .bulkSend(CONFIG.AVASHARKSCONTRACT, receiver, nfts)
        .send({
          gasLimit: String(gasLimit),
          maxFeePerGas: String(baseFee * 40),
          maxPriorityFeePerGas: String(baseFee),
          to: CONFIG.BULKSENDERCONTRACT,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          //setCheckedItems({});
          setFeedback("Sorry, something went wrong please try again.");
          setSendingNfts(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setCheckedItems({});
          getNFTs();
          setFeedback(`Sent`);
          setSendingNfts(false);
          dispatch(fetchData(blockchain.account, CONFIG.BULKSENDERCONTRACT));
        });
    } else {
      setSendingNfts(false);
      alert(
        "Please select/re-select atleast a Shark or provide an address before sending"
      );
    }
  };

  const activateBulkSender = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let baseFee = CONFIG.BASE_FEE;
    console.log("Gas limit: ", gasLimit);
    setFeedback(`Activating Bulk Sender...`);
    setBulkSend(true);
    blockchain.avaSharks.methods
      .setApprovalForAll(CONFIG.BULKSENDERCONTRACT, true)
      .send({
        gasLimit: String(gasLimit),
        maxFeePerGas: String(baseFee * 40),
        maxPriorityFeePerGas: String(baseFee),
        to: CONFIG.AVASHARKSCONTRACT,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again.");
        setBulkSend(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`Activated`);
        setBulkSend(false);
        dispatch(fetchData(blockchain.account, CONFIG.BULKSENDERCONTRACT));
      });
  };

  const getRewards = () => {
    if (blockchain.account !== "" && blockchain.avaSharksVault !== null) {
      dispatch(fetchData(blockchain.account, CONFIG.BULKSENDERCONTRACT));
    }
  };

  const getNFTs = async () => {
    let allImages = [];

    if (blockchain.account !== null) {
      console.log("blockchain.account", blockchain.account);
      const url = "https://deep-index.moralis.io/api/v2/".concat(
        blockchain.account,
        "/nft/0x3c965d9fcaf231f434186e9cf0c1e179307cd211?chain=avalanche&format=decimal"
      );

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios.defaults.headers.common = {
        "X-API-Key":
          "r0L1pU780aCielKk8uuwwFubBWcdz5yJuZHb4vdsvK4vvmwqE1zYjxcuwADMk3lE",
      };
      await axios({
        method: "get",
        url: url,
        config,
      })
        .then(async (nfts) => {
          axios.defaults.headers.common = {};

          setTotalSharks(nfts.data.result.length);
          for (let i = 0; i < nfts.data.result.length; i++) {
            if (nfts.data.result[i].metadata) {
              allImages.push({
                imageUrl: JSON.parse(nfts.data.result[i].metadata)["image"],
                name: JSON.parse(nfts.data.result[i].metadata)["name"],
              });
            } else {
              if (nfts.data.result[i].token_uri) {
                await axios({
                  method: "get",
                  url: nfts.data.result[i].token_uri,
                  config,
                })
                  .then((nft) => {
                    allImages.push({
                      imageUrl: nft.data.image,
                      name: nft.data.name,
                    });
                  })
                  .catch((err) => {
                    allImages.push({
                      imageUrl: null,
                      name: null,
                    });
                  });
              } else {
                allImages.push({
                  imageUrl: null,
                  name: null,
                });
              }
            }
          }

          console.log("allImages", allImages.length);
          setFetched(true);
          setApiError(false);
          setAllNftImages(allImages);
        })
        .catch((err) => {
          setApiError(true);
        });
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getRewards();
    getNFTs();
  }, [blockchain.account]);

  return (
    <>
      {blockchain.account === "" || blockchain.avaSharksVault === null ? (
        <div className="blocks">
          <s.Container ai={"center"} jc={"center"}>
            <s.TextDescription
              style={{
                textAlign: "center",
                padding: 15,
              }}
            >
              <p className="connectNetwork">
                {" "}
                Connect to the {CONFIG.NETWORK.NAME} network
              </p>
            </s.TextDescription>
            <s.SpacerSmall />
            {blockchain.errorMsg.includes("Change network") ? (
              <button
                className="button_network"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getRewards();
                }}
              >
                Wrong Network
              </button>
            ) : (
              <button
                className="button_connect"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getRewards();
                }}
              >
                CONNECT
              </button>
            )}
            {blockchain.errorMsg !== "" ? (
              <>
                <p className="erroMessage">{blockchain.errorMsg}</p>
              </>
            ) : null}
          </s.Container>
        </div>
      ) : (
        <>
          <div className="containerbox">
            <div className="flexbox">
              <div className="headcol">
                <p className="claimRewards">
                  Claim Rewards:{" "}
                  {data.totalRewards > 0
                    ? (data.totalRewards / 10 ** 18).toFixed(3) + " AVAX"
                    : "0 AVAX"}
                </p>
              </div>
              <div className="headcol">
                {data.totalRewards > 0 ? (
                  <button
                    className="button_claim"
                    disabled={claimingRewards ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      claimRewards();
                      getRewards();
                    }}
                  >
                    {" "}
                    {claimingRewards ? "CLAIMING" : "CLAIM"}
                  </button>
                ) : (
                  <button className="button_disable" disabled>
                    NO REWARDS
                  </button>
                )}
              </div>
            </div>

            <div className="textcontainer">
              <div className="textWhite">
                <p>MY NFTs</p>
              </div>
            </div>

            <div className="sendButton">
              {totalSharks > 0 && data.isApprovedForAll ? (
                <div>
                  <form>
                    <label className="receiverLabel">
                      {"Receiver Address:"}
                      <input
                        type="text"
                        value={receiver}
                        onChange={(e) => setReceiver(e.target.value)}
                        size="45"
                      />
                    </label>
                    <button
                      className="button_claim"
                      disabled={sendingNfts ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        //bulkSender();
                      }}
                    >
                      {" "}
                      {sendingNfts ? "SENDING" : "SEND"}
                    </button>
                  </form>
                </div>
              ) : totalSharks > 0 && !data.isApprovedForAll ? (
                <button
                  className="button_claim"
                  disabled={bulkSend ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault();
                    //activateBulkSender();
                  }}
                >
                  {" "}
                  {bulkSend ? "ACTIVATING" : "ACTIVATE BULK SEND"}
                </button>
              ) : (
                ""
              )}
            </div>
            {fetched ? (
              <div className="imageflex">
                {totalSharks > 0 ? (
                  allNftImages.map((x) => {
                    if (x.imageUrl)
                      return (
                        <div key={x.name} className="containersection">
                          <img className="nft" src={x.imageUrl} />
                          <input
                            type="checkbox"
                            className="checkbox"
                            id={x.name}
                            value={x.name}
                            onChange={handleChange}
                          />
                          <div className="middle">
                            <div className="text">{x.name}</div>
                          </div>
                        </div>
                      );
                    else
                      return (
                        <div key={Math.random()} className="containersection">
                          <img className="nft" src={error_loading} />
                        </div>
                      );
                  })
                ) : apiError ? (
                  <div className="textBlack">
                    <p className="textError">
                      Error Fetching NFTs. Try Again after sometime.
                    </p>
                  </div>
                ) : (
                  <div className="textBlack">
                    <p>YOU DON'T OWN ANY AVASHARK</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="textBlack">
                {apiError ? (
                  <p className="textError">
                    Error Fetching NFTs. Try Again after sometime.
                  </p>
                ) : (
                  <p>LOADING....</p>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Rewards;
